import { useCallback, useState } from 'react';
import axios from 'axios';
import useSelector from '../redux/typedHooks';
import { VehicleObject } from '../../../types/myVehiclesType';

interface Props {
  token?: string;
  licensePlateNumber: string;
}

interface ReturnType {
  vehicleInResponse?: VehicleObject;
  lookup: ({ token, licensePlateNumber }: Props) => void;
  isLoading: boolean;
  error: boolean;
}

export const useVehicleSearch = (): ReturnType => {
  const [vehicleInResponse, setVehicleInResponse] = useState<VehicleObject | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = useSelector((state) => state.application);

  const vehicleSearch = useCallback(
    async ({ token, licensePlateNumber }: Props) => {
      if (licensePlateNumber) {
        setIsLoading(true);
        try {
          const headers: {
            'Ocp-Apim-Subscription-Key': string;
            Authorization?: string;
          } = {
            'Ocp-Apim-Subscription-Key': apimContentHub,
          };
          if (token) {
            headers.Authorization = `Bearer ${token}`;
          }
          const res = await axios.get<VehicleObject>(
            `${apimBaseUrl}/${apimMembershipApi}/vehicles/${licensePlateNumber}`,
            {
              headers,
            },
          );

          setIsLoading(false);
          setError(false);
          setVehicleInResponse(res.data);
        } catch (e) {
          setIsLoading(false);
          setError(true);
        }
      }
      return () => {};
    },
    [apimBaseUrl, apimContentHub, apimMembershipApi],
  );

  const lookup = useCallback(
    ({ token, licensePlateNumber }: Props) => {
      vehicleSearch({ token, licensePlateNumber });
    },
    [vehicleSearch],
  );

  return { vehicleInResponse, lookup, isLoading, error };
};
